// React import is required because the react-selct is using React 16, which requires it for JSX.

import React from 'react'
import { OptionProps } from 'react-select'

import AudienceCard from '../../../Audiences/Card'

const CustomOptionAudience: React.FC<OptionProps<ValueOptionAudience>> = ({
  data,
  innerProps
}) => {
  const { selectedAudienceId, ...audience } = data

  return (
    <section className="border-bottom" {...innerProps}>
      <AudienceCard data={audience} selectedAudienceId={selectedAudienceId} />
    </section>
  )
}

export default CustomOptionAudience

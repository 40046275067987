import { useMemo } from 'react'
import { FiHome, FiPlus } from 'react-icons/fi'
import { useParams } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'

import { ButtonNavLink } from '~/components/Buttons'
import HeaderPages from '~/components/HeaderPages'
import { LogoStore } from '~/components/LogoStore'
import PageTitle from '~/components/PageTitle'
import { Badge } from '~/components/UI/Badge'
import { getRetailMediaAccountType, isAdvertiser, isPublisher } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { getAdvertiserService } from '~/modules/retailMedia/services/advertiser'
import { getPublisherService } from '~/modules/retailMedia/services/publisher'

type HeaderAudiencesProps = {
  pageTitle: string
  // Alternativa para um advertiser ID
  innerId?: AdvertiserId | PublisherId
}

const HeaderAudiences: React.FC<HeaderAudiencesProps> = ({
  pageTitle,
  innerId
}) => {
  const { t } = useAPPTranslation()

  const searchParams = useQueryParams()
  const { id: paramId } = useParams<{ id: AdvertiserId }>()
  const rmid = searchParams.get('rmid')
  const callback_url = searchParams.get('callback_url')
  const id = innerId || paramId || rmid

  const scope = getRetailMediaAccountType()

  const { data, isPending: loading } = useQuery({
    queryKey: ['accountInfo', { id }],
    queryFn: async () => {
      const service =
        scope === 'publisher' ? getAdvertiserService : getPublisherService

      const response = await service({
        url: { id },
        params: { account_info: true }
      })

      return response.data
    },
    enabled: !!id
  })

  /**
   * Memos
   */

  const insideProps = useMemo(() => {
    // if id is not AdvertiserId or PublisherId
    if (!id) {
      return {}
    }

    return {
      titleSideRightComponent: (
        <>
          {!loading && (
            <LogoStore
              size="medium"
              linkLogo={data?.account_logo}
              alt={data?.name}
              backgroundLogo={
                data?.account_theme?.header_color || 'transparent'
              }
            />
          )}
        </>
      ),
      subtitle: (
        <nav className="d-flex gap-1  align-items-center">
          <Badge iconLeft={<FiHome />} style={{ height: '22px' }}>
            {data?.name}
          </Badge>
          <ButtonNavLink
            to={`/retail-media/campaigns?rmid=${id}`}
            text={t('rm:seeCampaignDashboard')}
            size="xsmall"
            template="transparentPrimary"
            style={{ textDecoration: 'underline' }}
          />
        </nav>
      ),

      labelBreadcrumb: `${t(isPublisher() ? 'rm:backToAdvertiserSelection' : 'rm:backToPublisherSelection')}`,
      backTo: isPublisher()
        ? '/retail-media/audiences/advertisers'
        : '/retail-media/audiences'
    }
  }, [
    data?.account_logo,
    data?.account_theme?.header_color,
    data?.name,
    id,
    loading,
    t
  ])

  return (
    <>
      <PageTitle title={pageTitle} />

      <HeaderPages
        title={t('rm:audience.home.pageTitle')}
        subtitle={t(
          `rm:audience.home.pageSubtitle.${getRetailMediaAccountType()}`
        )}
        labelBreadcrumb={
          callback_url
            ? t('c:actions.back')
            : `${t(isPublisher() ? 'rm:backToAdvertiserSelection' : 'rm:backToPublisherSelection')}`
        }
        backTo={
          callback_url ||
          (isPublisher()
            ? '/retail-media/audiences/advertisers'
            : '/retail-media/audiences')
        }
        sideRightComponent={
          <ButtonNavLink
            to={`/retail-media/audiences/create${isAdvertiser() && id ? `?rmid=${id}` : ''}`}
            text={t('rm:audience.new')}
            iconLeft={<FiPlus />}
          />
        }
        {...insideProps}
      />
    </>
  )
}

export default HeaderAudiences

import { FiGlobe, FiLock } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import RangeSlider from '~/components/RangeSlider'
import Status from '~/components/Status'
import { Badge } from '~/components/UI/Badge'
import {
  formatMoneyV2,
  formatNumberWithK,
  isAdvertiser,
  isPublisher
} from '~/helpers'
import i from '~/i18n'

import { audienceStatus } from '../../lib/status'
import ButtonCreateCampaignWithAudience from '../ButtonCreateCampaignWithAudience'

const getRmid = (): string | null => {
  const url = decodeURIComponent(window.location.href)
  const match = url.match(/rmid=([a-f0-9-]+)/i)
  return match ? match[1] : null
}
const rmid = getRmid()

export const schameAudiencesPublisher: TableSchema<AudienceData> = {
  name: {
    title: i.t('rm:audience.list.name'),
    render: data => (
      <Link to={`/retail-media/audiences/view/${data.id}?rmid=${rmid}`}>
        {data.name}
      </Link>
    )
  },
  status: {
    title: i.t('rm:audience.list.status'),
    render: data => (
      <Status
        status={audienceStatus?.[data.status] || { label: data.status }}
      />
    )
  },
  reach_range: {
    title: i.t('rm:audience.list.potentialReach'),
    render: data => (
      <div>
        <div className="value fs-5">
          {formatNumberWithK({ number: data?.estimated_audience })}
        </div>
        <RangeSlider
          min={0}
          max={100}
          value={data?.reach_range || 0}
          readOnly
          size="small"
        />
      </div>
    )
  },
  cost: {
    title: i.t('rm:audience.list.cost'),
    render: data => (
      <div>
        <div className={`fs-5 ${!data.price ? 'text-neutral-low-light' : ''}`}>
          {formatMoneyV2({ value: data.price })}{' '}
        </div>
        <Badge
          size="small"
          template={data.price ? 'warning' : 'success'}
          text={
            data.price
              ? i.t('rm:AudienceCard.text.audienceCost.fixedCost')
              : i.t('rm:AudienceCard.text.audienceCost.noCost')
          }
        />
      </div>
    )
  },
  ...(isPublisher() && {
    visibility: {
      title: i.t('rm:audience.list.visibility'),
      render: data =>
        data.is_global ? (
          <Badge text="Global" iconLeft={<FiGlobe />} size="small" />
        ) : (
          <Badge text="Privado" iconLeft={<FiLock />} size="small" />
        )
    }
  }),
  campaignUsage: {
    title: i.t('rm:audience.list.campaignUsage'),
    render: data => data?.campaign_usage || '-'
  },
  ...(isAdvertiser() && {
    createCampaign: {
      render: data =>
        data.status === 'approved' && (
          <ButtonCreateCampaignWithAudience id={data.id} />
        )
    }
  })
}

import { NavLink } from 'react-router-dom'

import Status from '~/components/Status'
import i from '~/i18n'
import { accountInfo } from '~/modules/retailMedia/components/_tables/_elements/accountInfo'
import { statusType } from '~/modules/retailMedia/components/StatusBar/statusType'

export const campaignsWithAudienceTableSchema: TableSchema<Campaign> = {
  // ...(true ? { ...accountInfo } : {}),
  ...accountInfo,
  campaingInfo: {
    title: i.t('rm:Campaigns'),
    sortKey: 'name',
    render: rowData => (
      <div className="d-flex gap-2">
        <NavLink to={`/retail-media/campaign/view/${rowData.id}`}>
          {rowData.name}
        </NavLink>
      </div>
    ),
    showTotal: true
  },
  type: {
    title: i.t('rm:Type'),
    sortKey: 'ad_type',
    render: rowData => <div className="lh-1 fs-5">{rowData.adType}</div>,
    className: 'order__details col__infos col__title'
  },
  status: {
    title: i.t('rm:Status'),
    sortKey: 'status',
    render: rowData => (
      <div style={{ lineHeight: 1, fontSize: '.75rem' }}>
        <Status
          status={{
            label: statusType[rowData.status]?.label || rowData.status,
            type: statusType[rowData.status]?.color || 'default'
          }}
        />
      </div>
    )
  }
}

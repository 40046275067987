import { useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { t } from 'i18next'

import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import Table from '~/components/Table'
import { history } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'
import {
  listAdvertisersService,
  listAdvertisersServiceKey
} from '~/modules/retailMedia/services/advertiser-publisher'

import { advertisersLinkTableSchema } from './schema'

// import { Container } from './styles';

const AdvertisersTable: React.FC<{
  onAdd: (data: AdvertiserPublisherLink) => void
  onDelete: (data: AdvertiserPublisherLink) => void
  selectedAdvertisers: AdvertiserPublisherLink[]
}> = ({ onAdd, onDelete, selectedAdvertisers }) => {
  const params = useQueryParams()
  const page = Number(params.get('page')) || 1
  const quantity = Number(params.get('quantity')) || 50
  const advertiser_name = params.get('advertiser_name')
  const seller_id = params.get('seller_id')
  const tag_id = params.get('tag_id') as PublisherTagId

  /**
   * Get advertisers
   */

  const queryKey = [
    listAdvertisersServiceKey,
    page,
    quantity,
    advertiser_name,
    seller_id
  ]

  const {
    isPending,
    data: response
    // error
  } = useQuery({
    queryKey,
    queryFn: async () => {
      const payload = {
        page,
        quantity,
        advertiser_name,
        seller_id,
        tag_id
      }

      const response = await listAdvertisersService(payload)

      return response.data
    },
    placeholderData: previousData => previousData
  })

  //

  const handlePagination = useCallback(
    (value: number) => {
      params.set('page', String(value))
      history.push({ search: params.toString() })
    },
    [params]
  )

  const handleItemsPerPageChange = useCallback(
    (value: { value: number }) => {
      params.set('quantity', String(value.value))
      params.set('page', '1')
      history.push({ search: params.toString() })
    },
    [params]
  )

  return (
    <section>
      <Table
        smallerRowPadding
        schema={advertisersLinkTableSchema({
          handleAdd: onAdd,
          handleDelete: onDelete,
          selectedAdvertisers
        })}
        data={response?.data?.advertisers}
        total={response?.total}
        sideBorder
      />

      <Loading status={isPending} />

      <ListPagination
        total={response?.total}
        label={t(
          `rm:advertisers.pagination.label.${response?.total > 1 ? 'plural' : 'singular'}`
        )}
        currentPage={page}
        itemsPerPage={quantity}
        menuPlacement="top"
        onClickPagination={handlePagination}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </section>
  )
}

export default AdvertisersTable

import React, { useCallback, useEffect, useMemo } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import { IoSparklesOutline } from 'react-icons/io5'
import { useDispatch } from 'react-redux'

import { Button } from '~/components/Buttons'
import EmptyPage from '~/components/empty-page'
import { getRole, getUserId, history } from '~/helpers'
import { clearSeletectedChat } from '~/modules/chat/store/actions'
import { useAppSelector } from '~/store/hooks'

import { AttendantActions } from '../AttendantActions'
import ChatContent from '../ChatContent'
import ChatSubmitBar from '../ChatSubmitBar'
import HeaderChat from '../HeaderChat'
import ToggleFullScreen from '../ToggleFullScreen'

import * as S from './styles'

const ChatWrapper = () => {
  const disptach = useDispatch()

  const { fullScreen, selectedChat, autoRefresh, selectedStatus } =
    useAppSelector(({ chats }) => chats)

  const hasSession = useMemo(() => !!selectedChat?.session, [selectedChat])

  const userId = getUserId()
  const role = getRole()

  const isAdmin = useMemo(() => role === 'admin', [role])

  const userHasAccess = useMemo(
    () =>
      selectedStatus !== 'in_contact' || // ou se não está na aba 'in_contact
      selectedChat?.session?.userId === userId || // ou se você é o vinculado
      isAdmin, // ou se você é admin
    [isAdmin, selectedChat?.session?.userId, selectedStatus, userId]
  )

  useEffect(() => {
    console.log(
      `Atualização automática ${autoRefresh ? 'ligada' : 'desligada'}.`
    )
  }, [autoRefresh])

  /**
   * Handle back to list
   */

  const handleBackToList = useCallback(() => {
    disptach(clearSeletectedChat())
    history.replace(`/chats`)
  }, [disptach])

  /**
   * Handle mobile components
   */
  const { isMobile } = useAppSelector(state => state.layout)

  const shouldShowMobileActions = useMemo(() => isMobile, [isMobile])

  return (
    <S.Container className="ChatWrapper">
      {selectedChat ? (
        <>
          {(shouldShowMobileActions || fullScreen) && (
            <Button
              onClick={handleBackToList}
              iconLeft={<FiChevronLeft />}
              text="Voltar para a listagem de conversas"
              size="small"
              customWidth="100%"
              template="info"
              style={{ borderRadius: 0 }}
              className="goBack__button"
            />
          )}

          <HeaderChat />

          {!shouldShowMobileActions && <ToggleFullScreen />}

          <ChatContent />

          {hasSession && <ChatSubmitBar />}

          {hasSession && <AttendantActions />}
        </>
      ) : (
        <EmptyPage
          title="Selecione um chat"
          subtitle="Escolha uma conversa na lista ao lado para iniciar ou continuar o atendimento."
          svgPath="photos/chats-empty"
        />
      )}
    </S.Container>
  )
}

export default ChatWrapper

import { useQuery } from '@tanstack/react-query'

import { BadgeNotifications } from '~/components/BadgeNotifications'
import i from '~/i18n'
import Tabs from '~/modules/retailMedia/components/Tabs'
import { listAudiencesService } from '~/modules/retailMedia/services/audience'

const PublisherAudienceTabs: React.FC = () => {
  const { data } = useQuery({
    queryKey: ['list-audience-review'],
    queryFn: async () => {
      const { data } = await listAudiencesService({
        status: 'awaiting_approval'
      })

      return data?.total || 0
    }
  })

  const tabs = [
    {
      to: `/retail-media/audiences`,
      label: i.t('rm:audience.home.tab')
    },
    {
      to: `/retail-media/audiences/enriched-audiences`,
      label: i.t('rm:audience.audienceEnriched.tab')
    },
    {
      to: `/retail-media/audiences/advertisers`,
      label: i.t('rm:audience.advertisers.tab')
    },
    {
      to: `/retail-media/audiences/review`,
      label: i.t('rm:audience.review.tab'),
      iconRight:
        data > 0 ? <BadgeNotifications>{data}</BadgeNotifications> : null
    }
  ]

  return <Tabs tabs={tabs} />
}

export default PublisherAudienceTabs

import { useEffect, useMemo } from 'react'

import AlertInfo from '~/components/AlertInfo'
import BadgeIsValid from '~/components/BadgeIsValid'
import Panel from '~/components/Panel'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

import { BannerAddFormContent } from './BannerAddFormContent'

export const Banner = ({
  handleSectionValidity,
  bannersToTable,
  ...rest
}: BannerPanelProps) => {
  const { t } = useAPPTranslation()

  /**
   * Handle section is valid
   * * Refactor
   */

  const sectionIsValid = useMemo(
    () => !!bannersToTable?.length,
    [bannersToTable]
  )

  useEffect(
    () => handleSectionValidity?.(sectionIsValid),
    [handleSectionValidity, sectionIsValid]
  )

  return (
    <Panel
      iconLeft={<BadgeIsValid isValid={sectionIsValid} />}
      {...(!sectionIsValid && { className: 'border-danger' })}
      title={t('rm:campaign.create.Banner.panelTitle')}
      isCollapsible
    >
      {!rest?.selectedSegmentation && (
        <AlertInfo
          text={t(
            'rm:campaign.create.alerts.leastOneSegmentationConfiguration'
          )}
        />
      )}

      {rest?.selectedSegmentation && (
        <BannerAddFormContent
          bannersToTable={bannersToTable}
          {...(rest as BannerProps)}
        />
      )}
    </Panel>
  )
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  FiChevronDown,
  FiChevronRight,
  FiChevronUp,
  FiClock,
  FiEdit,
  FiExternalLink,
  FiPlus,
  FiTag
} from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import { Button } from '~/components/Buttons'
import LocationBadge from '~/components/LocationBadge'
import LastOrderByCustomer from '~/components/Order/LastOrderByCustomer'
import { getRole } from '~/helpers'
import {
  togglePanelCustomer,
  togglePanelTags,
  toggleBotStatus,
  togglePanelHistory
} from '~/modules/chat/store/actions'
import { useAppSelector } from '~/store/hooks'
import {
  clearCustomerState,
  getCustomer
} from '~/store/modules/customers/actions'

import { IconBot } from '../IconBot'
import { Label } from '../Label'
import TagLabel from '../TagLabel'

import * as S from './styles'

interface Props {
  avatarURL?: string | null
}

const HeaderChat = ({ avatarURL }: Props) => {
  const [collapse, setCollapse] = useState(true)

  const dispatch = useDispatch()

  const { selectedChat, customerId, updateChatLoading, fullScreen } =
    useAppSelector(({ chats }) => chats)

  const { customer } = useAppSelector(({ customers }) => customers)

  const { isMobile } = useAppSelector(({ layout }) => layout)

  useEffect(() => {
    const action = customerId
      ? () => getCustomer(customerId)
      : clearCustomerState

    dispatch(action())
  }, [dispatch, customerId])

  const handlePanelCustomer = () => dispatch(togglePanelCustomer())
  const handlePanelHistory = () => dispatch(togglePanelHistory())
  const handlePanelTags = () => dispatch(togglePanelTags())

  const hasSession = useMemo(() => !!selectedChat?.session, [selectedChat])

  const handleBotStatus = useCallback(
    () =>
      dispatch(
        toggleBotStatus({
          id: selectedChat.chatId,
          sessionId: selectedChat.session.sessionId,
          data: {
            botActive: !selectedChat.session.botActive,
            chatId: selectedChat.chatId
          }
        })
      ),
    [dispatch, selectedChat.chatId, selectedChat.session]
  )

  const tags: string[] = useMemo(
    () => selectedChat?.session?.tags,
    [selectedChat?.session?.tags]
  )

  const hasTag = useMemo(() => tags?.length > 0, [tags?.length])

  const isAdmin = getRole() === 'admin'

  return (
    <S.Container className="HeaderChat">
      <S.UserInfoContainer isCollapse={collapse}>
        <div className="user__infos">
          <div className="infos">
            <Button
              template="transparent"
              onClick={handlePanelCustomer}
              className="name"
              text={selectedChat?.customerName}
            />

            {!fullScreen && (
              <Button
                template="transparentPrimary"
                className="name"
                text={<FiExternalLink />}
                size="xsmall"
                as="a"
                title="Abrir chat em nova aba"
                href={`/chats/${selectedChat.chatId}/chat`}
                target="_blank"
                rel="noreferrer"
              />
            )}

            {!!customer?.metadata?.tags?.length && (
              <div className="customer-tags">
                <div className="customer-tags__list">
                  {customer.metadata.tags.map((tag, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Label key={`${index}-${tag}`}>{tag}</Label>
                  ))}
                </div>
              </div>
            )}

            <div className="tagsRow">
              {selectedChat.session && (
                <IconBot
                  $active={selectedChat.session.botActive}
                  useBot={selectedChat.useBot}
                  isLoading={updateChatLoading}
                  onClick={handleBotStatus}
                  type="button"
                />
              )}

              <LocationBadge
                locationName={selectedChat?.locationName}
                locationId={
                  selectedChat?.session?.locationId || selectedChat?.locationId
                }
              />

              {selectedChat?.customerId && (
                <LastOrderByCustomer customerId={selectedChat?.customerId} />
              )}
            </div>
          </div>

          <div className="collapseButton">
            <Button
              text={collapse ? <FiChevronDown /> : <FiChevronUp />}
              onClick={() => setCollapse(oldState => !oldState)}
              size="small"
              template="light"
            />
          </div>
        </div>

        <S.Aside isCollapse={isMobile && collapse}>
          {/* Customer info button */}
          <div className="d-inline-flex asideRow customerInfo">
            {isAdmin && (
              <S.WrapperButtonHistory>
                <Button
                  iconLeft={<FiClock />}
                  template="transparent"
                  onClick={handlePanelHistory}
                  text="Histórico da conversa"
                  size="xsmall"
                />
              </S.WrapperButtonHistory>
            )}

            {customerId ? (
              <Button
                iconRight={<FiChevronRight />}
                template="transparent"
                onClick={handlePanelCustomer}
                text="Informações do cliente"
                size="xsmall"
              />
            ) : (
              <Button
                template="transparentPrimary"
                iconRight={<FiChevronRight />}
                onClick={handlePanelCustomer}
                size="xsmall"
                text="Cadastrar cliente"
              />
            )}
          </div>

          {/* Tags */}
          {hasSession && (
            <div className="d-inline-flex asideRow tags">
              {hasTag && tags.map(tag => <TagLabel tagId={tag} key={tag} />)}

              {hasTag && (
                <button
                  type="button"
                  className="buttonMoreTags"
                  onClick={handlePanelTags}
                >
                  <FiEdit />
                </button>
              )}

              {!hasTag && (
                <button
                  type="button"
                  className="buttonMoreTags"
                  onClick={handlePanelTags}
                >
                  <div className="text">
                    <FiTag /> Adicionar tag à conversa
                  </div>
                </button>
              )}
            </div>
          )}
        </S.Aside>
      </S.UserInfoContainer>
    </S.Container>
  )
}

export default HeaderChat

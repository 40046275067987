import { useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { FaPlusCircle } from 'react-icons/fa'

import { Scope } from '@unform/core'

import { Button } from '~/components/Buttons'
import { Label } from '~/components/unform'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

import AttributeSelect from '../AttributeSelect'
import { fieldKeyOptions } from '../AttributeSelect/fieldKeyOptions'

const SegmentationRule: React.FC<SegmentationRuleProps> = ({ mode }) => {
  const { t } = useAPPTranslation()

  const content = useMemo(() => {
    if (mode === 'add') {
      return {
        title: t('c:words.inclusion'),
        action: t('c:actions.include'),
        template: 'text-bg-info text-white'
      }
    }

    if (mode === 'exclude') {
      return {
        title: t('c:words.exclusion'),
        action: t('c:actions.exclude'),
        template: 'text-bg-danger text-white'
      }
    }
  }, [mode, t])

  /**
   * <Handle rules>
   */
  const generateId = () =>
    `attribute_${Math.random().toString(36).slice(2, 11)}`

  const defaultRule = () => ({
    id: generateId()
  })

  const [rules, setRules] = useState<HandleSegmentationRuleDTO[]>([
    defaultRule()
  ])

  const handleAddRule = () => {
    setRules(prevState => [...prevState, defaultRule()])
  }

  const handleDeleteRule = (id: string) => {
    setRules(prevState => prevState.filter(rule => rule.id !== id))
  }

  const handleRuleChange = (data: HandleSegmentationRuleDTO) => {
    const updatedRules = rules.map(rule =>
      rule.id === data.id ? { ...rule, ...data } : rule
    )

    setRules(updatedRules)
  }

  /*** </Handle rules */

  /**
   * Determines if the add button is disabled.
   * The button is enabled only if all current rules have a `feature_key`.
   */
  const addButtonIsDisabled = useMemo(
    () => rules.some(rule => !rule.feature_key),
    [rules]
  )

  return (
    <Scope path={`rules[${mode}]`}>
      <fieldset className="d-flex flex-column">
        <Label text={content.title} />

        <div>
          <span className={`badge ${content.template} px-1 rounded-1`}>
            {content.action}
          </span>{' '}
          <Trans
            components={{ strong: <strong /> }}
            //@ts-expect-error: `i18nKey` is not recognized by the `Trans` component
            i18nKey="rm:audience.create.rules.select.description"
          />
        </div>

        <div className="rules d-flex flex-column gap-2 mt-1 py-1">
          {rules?.map(rule => (
            <AttributeSelect
              key={rule.id + rule.feature_key}
              rule={rule}
              selectedKeys={rules.map(item => item.feature_key)}
              onChange={handleRuleChange}
              {...(rules.length > 1 && { onDelete: handleDeleteRule })}
            />
          ))}
        </div>

        {rules.length < fieldKeyOptions.length && (
          <footer>
            <Button
              onClick={handleAddRule}
              template="transparentDark"
              disabled={addButtonIsDisabled}
              text={t('rm:audience.create.rules.addAnother')}
              iconLeft={<FaPlusCircle />}
              size="xsmall"
              customWidth="auto"
              className="p-1"
            />
          </footer>
        )}
      </fieldset>
    </Scope>
  )
}

export default SegmentationRule

import Panel from '~/components/Panel'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

import { useCampaignForm } from '../../hooks/useCampaignForm'
import * as S from '../../styles'
import Audience from '../Audience'

const AudienceGroup: React.FC = () => {
  const { t, tCapitalize: tc } = useAPPTranslation()
  const { campaignPermisions } = useCampaignForm()

  return (
    campaignPermisions?.allow_audience && (
      <Panel
        title={tc('rm:audience_one')}
        description={t('rm:campaign.audience.toggle.description')}
        isCollapsible
      >
        <S.Group data-template="highlight">
          <Audience />
        </S.Group>
      </Panel>
    )
  )
}

export default AudienceGroup

// Needs import React because react-select is a dependency
import { useCallback, useMemo, useRef, useState } from 'react'
import { Trans } from 'react-i18next'

import { useQuery } from '@tanstack/react-query'
import debounce from 'lodash/debounce'

import { FormGroup, InputHidden, Label } from '~/components/unform'
import Select from '~/components/unform/ReactSelect'
import { getWhiteLabelPublisherId, history } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { listAvailableAudienceService } from '~/modules/retailMedia/services/campaign/audience'

import AudienceCard from '../../../Audiences/Card'
import { useCampaignForm } from '../../hooks/useCampaignForm'
import CustomOptionAudience from './CustomOption'

const Audience = ({ publisherId }: SelectAudienceProps) => {
  const { audienceId: selectedAudienceId, setSelectedAudience } =
    useCampaignForm()

  const { t } = useAPPTranslation()

  const queryParams = useQueryParams()

  const publisherIdParam = useMemo(
    () =>
      (queryParams.get('rmid') as BrandedType<string, 'PublisherId'>) || null,
    [queryParams]
  )

  const { isWhiteLabel } = useAppInfo()

  const selectedPublisherId = useMemo(() => {
    if (publisherId) return publisherId

    return isWhiteLabel ? getWhiteLabelPublisherId() : publisherIdParam
  }, [isWhiteLabel, publisherId, publisherIdParam])

  /**
   * Handle params
   */
  const [query, setQuery] = useState<string>()
  const [preLoading, setPreLoading] = useState(false)

  const executeDebounceFunc = (func: () => void) => func()
  const handleDebounce = useRef(debounce(executeDebounceFunc, 1000)).current

  const handleQueryTerm = useCallback(
    (term: string | null) => {
      setPreLoading(!!term)

      if (term !== '') {
        handleDebounce(() => setQuery(term))
      }
    },
    [handleDebounce]
  )

  const queryStringSearchProduct = useMemo(
    () => ({
      name: query,
      publisher_id: selectedPublisherId
    }),
    [query, selectedPublisherId]
  )

  const { status, data } = useQuery({
    queryKey: ['list-available-audience', queryStringSearchProduct],
    queryFn: async () => {
      const { data } = await listAvailableAudienceService(
        queryStringSearchProduct
      )
      setPreLoading(false)
      return data
    }
  })

  /**
   * memos
   */

  const { audiences, loading, total, totalGlobal } = useMemo(() => {
    return {
      audiences: data?.data.map(audience => ({
        ...audience,
        selectedAudienceId,
        label: audience.name,
        id: audience.id as BrandedType<number, 'AudienceId'>
      })) as ValueOptionAudience[],
      loading: status === 'pending',
      total: data?.total,
      totalGlobal: data?.totalGlobal
    }
  }, [data?.data, data?.total, data?.totalGlobal, selectedAudienceId, status])

  const selectedAudience = useMemo(
    () => audiences?.find(({ id }) => id === selectedAudienceId),
    [audiences, selectedAudienceId]
  )

  /**
   *
   * handles
   */

  const handleChange = useCallback(
    (value: ValueOptionAudience) => {
      const isDelete = value?.id === selectedAudience?.id || !value?.id

      setSelectedAudience(isDelete ? null : value)

      if (isDelete) queryParams.delete('audience')

      if (value?.id && !isDelete) queryParams.set('audience', String(value.id))

      history.replace({ search: queryParams.toString() })
    },
    [queryParams, selectedAudience?.id, setSelectedAudience]
  )

  /**
   * memo
   */

  const helperText = useMemo(() => {
    if (loading) {
      return t('rm:campaign.audience.loading')
    }

    if (query) {
      return (
        <Trans
          components={{ strong: <strong /> }}
          // @ts-expect-error: Trans component does not have a type definition for components prop
          i18nKey="rm:campaign.audience.helperText.searching"
          values={{
            filteredQuery: query,
            total,
            totalGlobal
          }}
        />
      )
    }

    return t('rm:campaign.audience.helperText.availableCategory', {
      count: totalGlobal || total
    })
  }, [loading, query, t, total, totalGlobal])

  return (
    <FormGroup className="w-100">
      {!selectedAudience && (
        <section className="border border-1 border-neutral-high-dark rounded p-3">
          <Label
            htmlFor="audience-select"
            text={`${t('rm:campaign.audience.label')} (${t('c:words.optional')})`}
            helperText={helperText}
          />

          <Select
            name="audience-select"
            placeholder={`${t('rm:campaign.audience.placeholder')}`}
            options={audiences || []}
            components={{ Option: CustomOptionAudience }}
            loading={loading || preLoading}
            onChange={handleChange}
            isClearable
            defaultValue={selectedAudience}
            isSearchable
            onInputChange={handleQueryTerm}
            loadingMessage={() => `${t('c:words.loading')}...`}
            noOptionsMessage={() => t('c:messages.noResultsFound')}
            allowCreateWhileLoading
          />
        </section>
      )}

      {selectedAudience && (
        <section className="border border-1 border-neutral-high-dark rounded">
          <AudienceCard
            data={selectedAudience}
            selectedAudienceId={selectedAudienceId}
            isSelectedPreview
            onSelect={handleChange}
          />
        </section>
      )}

      <InputHidden name="audience" value={selectedAudienceId} />
    </FormGroup>
  )
}

export default Audience

import { FiTrash } from 'react-icons/fi'

import { t } from 'i18next'

import { Button } from '~/components/Buttons'
import { Badge } from '~/components/UI/Badge'

type Props = {
  handleAdd?: (data: AdvertiserPublisherLink) => void
  handleDelete?: (data: AdvertiserPublisherLink) => void
  selectedAdvertisers?: AdvertiserPublisherLink[]
}

export const advertisersLinkTableSchema: (
  data: Props
) => TableSchema<AdvertiserPublisherLink> = ({
  handleAdd,
  handleDelete,
  selectedAdvertisers
}) => ({
  name: {
    title: t('rm:Advertisers'),
    render: rowData => (
      <>
        <strong>{rowData.advertiser_name}</strong>
        <div className="lh-1">
          <small>{rowData?.brands?.join(', ')}</small>
        </div>
      </>
    ),
    showTotal: true
  },
  tags: {
    title: t('rm:config.advertisers.list.table.column.title.advertiserTags'),
    render: rowData => (
      <section className="advertiser-ad_items d-flex flex-wrap gap-1 align-items-center">
        {rowData?.advertiser_tags?.map(item => (
          <Badge key={item.id} text={item.label} className="m-0" />
        ))}
      </section>
    )
  },
  sellerId: {
    title: t('rm:config.advertisers.list.table.column.title.sellerId'),
    render: rowData => rowData.seller_id
  },
  actions: {
    render: rowData => (
      <section className="d-flex justify-content-center">
        {handleAdd &&
          selectedAdvertisers?.findIndex(
            item => item.advertiser_id === rowData.advertiser_id
          ) === -1 && (
            <Button
              text={t('c:actions.add')}
              onClick={() => handleAdd(rowData)}
              size="xsmall"
              customWidth="auto"
            />
          )}

        {handleDelete &&
          selectedAdvertisers?.findIndex(
            item => item.advertiser_id === rowData.advertiser_id
          ) > -1 && (
            <Button
              iconLeft={<FiTrash />}
              template="transparentDanger"
              onClick={() => handleDelete(rowData)}
              size="xsmall"
              customWidth="auto"
            />
          )}
      </section>
    )
  }
})

import { useMemo } from 'react'
import { FiTrash } from 'react-icons/fi'

import { useQuery } from '@tanstack/react-query'
import { Scope } from '@unform/core'

import { Button } from '~/components/Buttons'
import ReactSelect from '~/components/unform/ReactSelect'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { listAttributesService } from '~/modules/retailMedia/services/audience/attributes'

import { fieldKeyOptions } from './fieldKeyOptions'

/**
 * Compomente que permite a seleção de atributos para a regra de segmentação.
 */

const AttributeSelect: React.FC<AttributeSelectProps> = ({
  rule,
  selectedKeys,
  onChange,
  onDelete
}) => {
  const { t } = useAPPTranslation()

  const publisher_id = useQueryParams().get('rmid') as PublisherId
  /**
   * <Handle options>
   * Check for the field key select.
   */
  const options = useMemo(
    () =>
      fieldKeyOptions.map(item => ({
        ...item,
        disabled: selectedKeys.includes(item.value)
      })),
    [selectedKeys]
  )
  /*** </Handle options> */

  /**
   * <Handle attibutes>
   */
  const { data } = useQuery({
    enabled: !!rule.feature_key,
    queryKey: [
      'audiences-attibutes',
      { featureKey: rule.feature_key },
      publisher_id
    ],
    queryFn: async () => {
      const response = await listAttributesService({
        feature_key: rule.feature_key,
        publisher_id
      })

      return response.data
    }
  })
  /*** </Handle attibutes> */

  /**
   * <Handle attibutes options>
   */
  const attributeOptions = useMemo(
    () =>
      data?.data?.map(item => {
        let translatedLabel = item.feature_value
        if (item.feature_value === 'F') {
          translatedLabel = t('rm:audience.create.rules.gender.f')
        } else if (item.feature_value === 'M') {
          translatedLabel = t('rm:audience.create.rules.gender.m')
        }

        return {
          label: translatedLabel,
          value: item.feature_value
        }
      }),
    [data, t]
  )

  return (
    <Scope path={rule.id}>
      <fieldset className="d-flex gap-1">
        <div style={{ minWidth: '8rem' }}>
          <ReactSelect
            placeholder={t('rm:audience.create.rules.fieldKey.placeholder')}
            options={options}
            name="feature_key"
            isOptionDisabled={(option: AttributeFieldKeyOption) =>
              option.disabled
            }
            isClearable={false}
            defaultValue={options.find(item => item.value === rule.feature_key)}
            onChange={(data: AttributeFieldKeyOption) =>
              onChange({ id: rule.id, feature_key: data?.value })
            }
          />
        </div>

        <div className="flex-fill">
          <ReactSelect
            placeholder={t('rm:audience.create.rules.fieldValue.placeholder')}
            options={attributeOptions}
            name="feature_values"
            isMulti
            closeMenuOnSelect={false}
          />
        </div>

        {onDelete && (
          <Button
            text={<FiTrash />}
            template="light"
            onClick={() => onDelete(rule.id)}
            customWidth="auto"
          />
        )}
      </fieldset>
    </Scope>
  )
}

export default AttributeSelect

import useQueryParams from '~/hooks/useQuery'
import i from '~/i18n'
import Tabs from '~/modules/retailMedia/components/Tabs'

const AdvertiserAudienceTabs: React.FC = () => {
  const query = useQueryParams()
  const id = query.get('rmid')

  const linkParam = id ? `?rmid=${id}` : ''

  const tabs = [
    {
      to: `/retail-media/audiences${linkParam}`,
      label: i.t('rm:audience.home.tab')
    },
    {
      to: `/retail-media/audiences/publisher${linkParam}`,
      label: i.t('rm:audience.publisherAudiences')
    }
  ]

  return <Tabs tabs={tabs} />
}

export default AdvertiserAudienceTabs

import { useParams } from 'react-router-dom'

import i from '~/i18n'
import Tabs from '~/modules/retailMedia/components/Tabs'

const PublisherAudienceTabs: React.FC = () => {
  const { id } = useParams<{ id: AdvertiserId }>()

  const tabs = [
    {
      to: `/retail-media/audiences/advertiser/${id}`,
      label: i.t('rm:audience.tab.createdByAdvertiser')
    },
    {
      to: `/retail-media/audiences/advertiser/${id}/available`,
      label: i.t('rm:audience.tab.availableToAdvertiser')
    }
  ]

  return <Tabs tabs={tabs} />
}

export default PublisherAudienceTabs

import {
  attributesFieldKeys,
  audienceAttributeLabels
} from '../../../lib/attributes'

export const fieldKeyOptions: AttributeRuleFieldKeyOptions =
  attributesFieldKeys.map(key => ({
    value: key,
    label: audienceAttributeLabels[key]
  }))

import { useMemo } from 'react'

import AlertInfo from '~/components/AlertInfo'
import Panel from '~/components/Panel'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

import { useCampaignForm } from '../../hooks/useCampaignForm'
import BudgetType from '../BudgetTypeScope'
import CostTypeScope from '../CostTypeScope'
import Placements from '../Placements'

const AdvancedSettings: React.FC<AdvancedSettingsFieldsetProps> = ({
  targeting
}) => {
  const { t } = useAPPTranslation()
  const { campaignPermisions, costType, selectedType } = useCampaignForm()

  const isBanner = useMemo(() => selectedType === 'banner', [selectedType])

  const allowCostType = useMemo(
    () => isBanner && !!campaignPermisions?.allow_daily_cost_campaign,
    [campaignPermisions?.allow_daily_cost_campaign, isBanner]
  )
  const allowPlacements = useMemo(
    () => isBanner && !!campaignPermisions?.allow_placement_campaign,
    [campaignPermisions?.allow_placement_campaign, isBanner]
  )
  const allowBudgetType = useMemo(() => costType === 'cpmcpc', [costType])

  return (
    <Panel
      title={t('rm:AdvancedSettings.title')}
      isCollapsible
      initialCollapsibleState={false}
    >
      {allowCostType && (
        <>
          <CostTypeScope />
          <hr />
        </>
      )}

      {allowPlacements && (
        <>
          <Placements
            targeting={targeting}
            adType={selectedType as AdTypeWithTargeting}
          />

          <hr />
        </>
      )}

      {allowBudgetType && <BudgetType />}
    </Panel>
  )
}

export default AdvancedSettings

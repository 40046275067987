import { FiPlus } from 'react-icons/fi'

import { ButtonNavLink } from '~/components/Buttons'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

const ButtonCreateCampaignWithAudience: React.FC<{ id: AudienceId }> = ({
  id,
  ...rest
}) => {
  const { t } = useAPPTranslation()
  const getRmid = (): string | null => {
    const url = decodeURIComponent(window.location.href)
    const match = url.match(/rmid=([a-f0-9-]+)/i)
    return match ? match[1] : null
  }
  const rmid = getRmid()
  const url = rmid
    ? `/retail-media/campaign/create?audience=${id}&rmid=${rmid}`
    : `/retail-media/campaign/create?audience=${id}`

  return (
    <ButtonNavLink
      to={url}
      text={t('rm:createCampaign')}
      iconLeft={<FiPlus />}
      customWidth="auto"
      template="light"
      size="small"
      {...rest}
    />
  )
}

export default ButtonCreateCampaignWithAudience

import Panel from '~/components/Panel'
import i from '~/i18n'

import SegmentationRule from './Rule'

const SegmentationRules = () => (
  <Panel
    title={i.t('rm:audience.rules')}
    description={i.t('rm:audience.create.rules.description')}
  >
    <SegmentationRule mode="add" />

    {/* <hr /> */}

    {/* <SegmentationRule mode="exclude" /> */}
  </Panel>
)

export default SegmentationRules

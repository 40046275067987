import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'

import AlertInfo from '~/components/AlertInfo'
import Loading from '~/components/Loading'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import { Badge } from '~/components/UI/Badge'
import { useErrorApiAlert } from '~/hooks/useErrorApiAlert'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { getLabelAdType } from '~/modules/retailMedia/dtos/common/campaign/format'
import { listCampaignsByAudienceService } from '~/modules/retailMedia/services/campaign/audience'

import { campaignsWithAudienceTableSchema } from './schema'

// import { Container } from './styles';

export const CampaignsByAudience: React.FC = () => {
  const { t } = useAPPTranslation()

  const { audienceId: id } = useParams()
  const audienceId = Number(id) as AudienceId

  const { data, error, isPending } = useQuery({
    queryKey: ['audience-campaigns', 'list', audienceId],
    queryFn: async () => {
      const {
        data: { data, total }
      } = await listCampaignsByAudienceService({
        audienceId
      })

      const responseDTO = data.map(campaign => ({
        ...campaign,
        adType: getLabelAdType(campaign.ad_type),
        publisher: {
          accountId: campaign.publisher_account_id,
          bgColor: campaign.publisher_account_theme.primary_color,
          id: campaign.publisher_id,
          name: campaign.publisher_name,
          logo: campaign.publisher_account_logo
        } as AccountThemeRetailMedia,
        advertiser: {
          accountId: campaign.advertiser_account_id,
          bgColor: campaign.advertiser_account_theme.primary_color,
          id: campaign.advertiser_id,
          name: campaign.advertiser_name,
          logo: campaign.advertiser_account_logo
        } as AccountThemeRetailMedia
      }))

      return { data: responseDTO, total }
    }
  })

  const [AlertOnError, setError] = useErrorApiAlert({
    title: t('c:messages.errorOccurred.title')
  })

  useEffect(() => {
    setError(error)
  }, [error, setError])

  return (
    <Panel
      title={
        <section className="d-flex align-items-center gap-1">
          <strong>{t('rm:audience.campaigns.title')}</strong>
          {data && <Badge size="small" text={data?.total} />}
        </section>
      }
      description={t('rm:audience.campaigns.description')}
    >
      <Loading status={isPending} />

      <AlertOnError />

      {data?.total === 0 && (
        <AlertInfo text={t('rm:audience.campaigns.noCampaigns')} />
      )}

      {!!data?.total && (
        <Table data={data?.data} schema={campaignsWithAudienceTableSchema} />
      )}
    </Panel>
  )
}
